import React from 'react';
import DatePicker from 'react-datepicker';

import {
    DetailText,
    Strong,
    HStack,
    ChevronDownIcon,
    bgColorClassnames,
    outlineColorClassnames,
    textColorClassnames,
} from '@sphericsio/design-system';

import 'react-datepicker/dist/react-datepicker.css';
import {CalendarIcon} from './icons';

type CustomDatePickerInputProps = {
    value?: string;
    onClick?: () => void;
};
// eslint-disable-next-line react/display-name
const CustomDatePickerInput = React.forwardRef<HTMLDivElement, CustomDatePickerInputProps>(
    ({value, onClick}, ref) => {
        return (
            <div className="cursor-pointer" ref={ref} onClick={onClick}>
                <div
                    className={`rounded-xl border py-2 px-4 ${outlineColorClassnames()} ${bgColorClassnames(
                        'white',
                    )}`}
                >
                    <HStack>
                        <div className={`w-6 h-6 ${textColorClassnames()}`}>
                            <CalendarIcon />
                        </div>
                        <div className="w-36">
                            <DetailText inline={false}>{value}</DetailText>
                        </div>
                        <div className={`w-6 h-6 ${textColorClassnames()}`}>
                            <ChevronDownIcon />
                        </div>
                    </HStack>
                </div>
            </div>
        );
    },
);

type CustomDatePickerProps = {
    value?: Date;
    onChange: (date: Date) => void;
    minDate?: Date;
    maxDate?: Date;
    title?: string;
};
export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
    value,
    onChange,
    minDate,
    maxDate,
    title,
}) => {
    let openToDate = value;
    if (value != null) {
        if (minDate != null && minDate > value) {
            openToDate = minDate;
        } else if (maxDate != null && maxDate < value) {
            openToDate = maxDate;
        }
    }
    return (
        <div>
            {title && (
                <div className="mb-5">
                    <DetailText inline={false}>
                        <Strong>{title}</Strong>
                    </DetailText>
                </div>
            )}
            <DatePicker
                dateFormat={'dd/MM/yyyy'}
                selected={value}
                onChange={onChange}
                customInput={<CustomDatePickerInput />}
                minDate={minDate}
                maxDate={maxDate}
                title={title}
                openToDate={openToDate}
                popperPlacement="bottom"
            />
        </div>
    );
};

import React from 'react';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';

import {Loading} from '../components/loading';
import {ErrorCard} from '../components/error-card';
import {MerchantHeader} from '../components/merchants/header';
import {View} from '../components/view';
import {MerchantTransactions} from '../components/merchants/transactions';
import {MerchantMatchers} from '../components/merchants/matchers';
import {FragmentType, graphql, useFragment} from '../graphql/generated';
import {TaxonomyTermDisplay_TaxonomyFragment} from '../graphql/generated/graphql';
import {TaxonomyTermDisplayTaxonomyFragmentDocument} from '../components/taxonomy-term-display';

export const MerchantDetailAdminMerchantFragmentDocument = graphql(/* GraphQL */ `
    fragment MerchantDetail_AdminMerchant on AdminMerchant {
        id
        identified
        name
        identified
        sicCodes {
            sic_code
            description
        }
        carbonIntensities {
            id
            carbon_intensity
            year
            reference_url
        }
        metadata {
            id
            metadata_type
            metadata_value
        }
        aliases {
            id
            name_alias
        }
        taxonomy {
            overrideTaxonomyTerm
            suggestedTaxonomyTerm
        }
        matchers {
            id
            notes
            query
            enabled
        }
    }
`);

type MerchantDetailViewProps = {
    merchant: FragmentType<typeof MerchantDetailAdminMerchantFragmentDocument>;
    taxonomies: FragmentType<typeof TaxonomyTermDisplayTaxonomyFragmentDocument>[];
};
function MerchantDetailView(props: MerchantDetailViewProps) {
    const merchant = useFragment(MerchantDetailAdminMerchantFragmentDocument, props.merchant);
    const taxonomies = useFragment(TaxonomyTermDisplayTaxonomyFragmentDocument, props.taxonomies);

    return (
        <>
            <MerchantHeader
                merchant={merchant}
                taxonomies={taxonomies as TaxonomyTermDisplay_TaxonomyFragment[]}
            />
            {merchant.identified && (
                <div className="mt-4">
                    <MerchantMatchers merchant={merchant} />
                </div>
            )}
            <div className="mt-4">
                <MerchantTransactions merchantId={merchant.id} />
            </div>
        </>
    );
}

const GetMerchantDetailDocument = graphql(/* GraphQL */ `
    query GetMerchantDetail($id: ID!) {
        getAdminMerchantById(id: $id) {
            ...MerchantDetail_AdminMerchant
        }
        getAllTaxonomies {
            ...TaxonomyTermDisplay_Taxonomy
        }
    }
`);
const MerchantDetail: React.FC = () => {
    const {merchantId} = useParams();
    const {loading, error, data} = useQuery(GetMerchantDetailDocument, {
        variables: {id: merchantId},
    });

    return (
        <View>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <MerchantDetailView
                    merchant={data.getAdminMerchantById}
                    taxonomies={data.getAllTaxonomies}
                />
            )}
        </View>
    );
};

export {MerchantDetail};
